
.icon-voucher:before {
  content: "\e96d";
}
.icon-mercadopago:before {
  content: "\e95c";
}
.icon-documents:before {
  content: "\e969";
}
.icon-inbox:before {
  content: "\e964";
}
.icon-prompt:before {
  content: "\e979";
}
.icon-cash:before {
  content: "\e95d";
}
.icon-file-code:before {
  content: "\e913";
}
.icon-file-video:before {
  content: "\e914";
}
.icon-file-audio:before {
  content: "\e915";
}
.icon-file-zip:before {
  content: "\e916";
}
.icon-file-presentation:before {
  content: "\e918";
}
.icon-file-spreadsheet:before {
  content: "\e919";
}
.icon-file-document:before {
  content: "\e91a";
}
.icon-file-pdf:before {
  content: "\e91b";
}
.icon-file-text:before {
  content: "\e91c";
}
.icon-file:before {
  content: "\e91d";
}
.icon-error-log:before {
  content: "\e90d";
}
.icon-closed-day:before {
  content: "\e978";
}
.icon-closed-reason:before {
  content: "\e977";
}
.icon-event:before {
  content: "\e976";
}
.icon-notification:before {
  content: "\e975";
}
.icon-agent:before {
  content: "\e974";
}
.icon-quick-reply:before {
  content: "\e973";
}
.icon-medicine:before {
  content: "\e972";
}
.icon-code:before {
  content: "\e971";
}
.icon-logistic-reason:before {
  content: "\e970";
}
.icon-change-update:before {
  content: "\e96f";
}
.icon-time:before {
  content: "\e96e";
}
.icon-percent:before {
  content: "\e96c";
}
.icon-banner:before {
  content: "\e96b";
}
.icon-document:before {
  content: "\e96a";
}
.icon-change-product:before {
  content: "\e968";
}
.icon-space:before {
  content: "\e967";
}
.icon-minus:before {
  content: "\e966";
}
.icon-tag:before {
  content: "\e965";
}
.icon-send:before {
  content: "\e963";
}
.icon-payment-method:before {
  content: "\e962";
}
.icon-logistic:before {
  content: "\e961";
}
.icon-copy:before {
  content: "\e960";
}
.icon-schedule:before {
  content: "\e95f";
}
.icon-attachment:before {
  content: "\e95e";
}
.icon-coverage-type:before {
  content: "\e95b";
}
.icon-print:before {
  content: "\e95a";
}
.icon-commission:before {
  content: "\e959";
}
.icon-zoom:before {
  content: "\e958";
}
.icon-help:before {
  content: "\e957";
}
.icon-tooltip:before {
  content: "\e957";
}
.icon-white-email:before {
  content: "\e956";
}
.icon-search:before {
  content: "\e954";
}
.icon-expand:before {
  content: "\e955";
}
.icon-cancel:before {
  content: "\e953";
}
.icon-cancel-reason:before {
  content: "\e952";
}
.icon-product:before {
  content: "\e951";
}
.icon-delivery:before {
  content: "\e950";
}
.icon-reject-reason:before {
  content: "\e94f";
}
.icon-pharma-action:before {
  content: "\e94e";
}
.icon-unit-type:before {
  content: "\e94d";
}
.icon-shape-type:before {
  content: "\e94c";
}
.icon-size-type:before {
  content: "\e94b";
}
.icon-administration-route:before {
  content: "\e94a";
}
.icon-potency-type:before {
  content: "\e949";
}
.icon-mono-drug:before {
  content: "\e948";
}
.icon-coverage:before {
  content: "\e947";
}
.icon-drug-type:before {
  content: "\e946";
}
.icon-change-catalog:before {
  content: "\e945";
}
.icon-contact:before {
  content: "\e944";
}
.icon-state-group:before {
  content: "\e943";
}
.icon-map:before {
  content: "\e942";
}
.icon-objective:before {
  content: "\e941";
}
.icon-holiday:before {
  content: "\e940";
}
.icon-laboratory:before {
  content: "\e93f";
}
.icon-macro-category:before {
  content: "\e93c";
}
.icon-category:before {
  content: "\e93d";
}
.icon-sub-category:before {
  content: "\e93e";
}
.icon-bank:before {
  content: "\e93b";
}
.icon-retirement-type:before {
  content: "\e93a";
}
.icon-transaction-type:before {
  content: "\e938";
}
.icon-provider-log:before {
  content: "\e937";
}
.icon-gateway:before {
  content: "\e934";
}
.icon-transaction:before {
  content: "\e939";
}
.icon-price:before {
  content: "\e939";
}
.icon-payment-type:before {
  content: "\e935";
}
.icon-card-type:before {
  content: "\e936";
}
.icon-login:before {
  content: "\e933";
}
.icon-sale-type:before {
  content: "\e932";
}
.icon-import:before {
  content: "\e930";
}
.icon-export:before {
  content: "\e931";
}
.icon-order:before {
  content: "\e92f";
}
.icon-hide:before {
  content: "\e92e";
}
.icon-health-type:before {
  content: "\e92d";
}
.icon-client:before {
  content: "\e92c";
}
.icon-subsidiary:before {
  content: "\e92a";
}
.icon-pharmacy:before {
  content: "\e92b";
}
.icon-state:before {
  content: "\e929";
}
.icon-menu:before {
  content: "\e928";
}
.icon-checkbox:before {
  content: "\e926";
}
.icon-checkedbox:before {
  content: "\e927";
}
.icon-down:before {
  content: "\e924";
}
.icon-up:before {
  content: "\e925";
}
.icon-details:before {
  content: "\e923";
}
.icon-admin:before {
  content: "\e922";
}
.icon-delete:before {
  content: "\e920";
}
.icon-more:before {
  content: "\e921";
}
.icon-add:before {
  content: "\e91e";
}
.icon-create:before {
  content: "\e91e";
}
.icon-plus:before {
  content: "\e91e";
}
.icon-edit:before {
  content: "\e91f";
}
.icon-directory:before {
  content: "\e917";
}
.icon-media:before {
  content: "\e912";
}
.icon-email-queue:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e90f";
}
.icon-view:before {
  content: "\e910";
}
.icon-close:before {
  content: "\e90e";
}
.icon-filter:before {
  content: "\e90c";
}
.icon-action-log:before {
  content: "\e90b";
}
.icon-next:before {
  content: "\e907";
}
.icon-closed:before {
  content: "\e907";
}
.icon-prev:before {
  content: "\e908";
}
.icon-last:before {
  content: "\e909";
}
.icon-first:before {
  content: "\e90a";
}
.icon-home:before {
  content: "\e906";
}
.icon-back:before {
  content: "\e904";
}
.icon-settings:before {
  content: "\e905";
}
.icon-logout:before {
  content: "\e902";
}
.icon-user:before {
  content: "\e903";
}
.icon-password:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
