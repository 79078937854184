@font-face {
    font-family: "icomoon";
    src:
        url("../Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("../Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("../Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    color: rgb(34, 34, 34);
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea,
button {
    font-family: "Lato", sans-serif;
}



/**
 * The Scrollbars
 */
section::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
section::-webkit-scrollbar-thumb {
    visibility: hidden;
    border: 2px solid transparent;
    border-radius: 999px;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.1);
}
section::-webkit-scrollbar-thumb:vertical {
    border-left-width: 3px;
    min-height: 20px;
}
section::-webkit-scrollbar-thumb:horizontal {
    border-top-width: 3px;
    min-width: 20px;
}
section:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}
